import {handleErrorWithSentry, browserTracingIntegration} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import {HttpError, TimeoutError} from '@canals/api-client/src/http_client';
import {PUBLIC_IS_LOCAL, PUBLIC_SENTRY_DSN, PUBLIC_CANALS_ENV} from '$env/static/public';
import {ACCOUNT_DISABLED, ORDER_SUBMITTED} from '@canals/shared/src/api_error_codes';

// Set this to true when you are working with Sentry locally and want all
// data to be sent to Sentry. For this to work, you must override the
// PUBLIC_SENTRY_DSN environment variable in your config/.env.local file.
// NOTE: Keep the TS type to prevent committing with debug mode enabled.
const DEBUG_MODE: false = false as const;

if (PUBLIC_IS_LOCAL === 'false' || DEBUG_MODE) {
  Sentry.init({
    debug: DEBUG_MODE,
    dsn: PUBLIC_SENTRY_DSN,
    environment: PUBLIC_CANALS_ENV,
    integrations: [browserTracingIntegration()],

    tracesSampleRate: DEBUG_MODE ? 1 : 0.5,
    tracePropagationTargets: ['localhost', /(.*\.)?api.canals.ai/],

    async beforeSend(event, hint) {
      // Would've already been reported by the backend
      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 500) return null;
      if (hint.originalException instanceof HttpError && hint.originalException.code === ACCOUNT_DISABLED) return null;

      // Will happen sometimes if user has submitted order in another tab, so don't send an alert
      if (hint.originalException instanceof HttpError && hint.originalException.code === ORDER_SUBMITTED) return null;

      // Usually a superuser goes to some page while impersonating someone who doesn't have access to it
      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 403) return null;

      if (
        hint.originalException instanceof Error &&
        hint.originalException.message.startsWith(
          'Non-Error promise rejection captured with value: Object Not Found Matching'
        )
      )
        return null;

      // Also most often a superuser opening an order while impersonating someone from another org
      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 404) return null;

      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 401) {
        Sentry.addBreadcrumb({
          category: 'auth',
          message: `Received 401 from API. User: ${event.user?.id}. Path: ${window.location.pathname}`,
        });
      }

      // User has logged out and was navigated to login page, but there was a pending request that then got rejected
      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 401 && !event.user) {
        // Wait for the url to change to /login
        await new Promise((resolve) => setTimeout(resolve, 300));
        if (window.location.pathname === '/login') return null;
      }

      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 401 && event.user) {
        // User's session probably expired.
        window.openSessionExpiredModal();
        return null;
      }

      if (hint.originalException instanceof TimeoutError) return null;
      if (event.exception?.values?.[0]?.value?.includes('chatwoot')) return null;
      if (event.exception?.values?.[0]?.value?.includes('Cannot redefine property: googletag')) return null; // Adblocker: https://stackoverflow.com/questions/78103254/cannot-redefine-property-googletag
      if (event.exception?.values?.[0]?.value === 'Network Error') return null;
      return event;
    },
  });
}

export const handleError = handleErrorWithSentry();
